<template>
	<a-modal v-model="show" title="物品信息" :width="700" @ok="doSubmit" @cancel="_close" centered destroyOnClose>
		<a-form-model ref="formRef" :model="formData" :rules="formRule">
			<a-row :gutter="15">
				<a-col span="6" v-if="getOrganId() == 0">
					<a-form-model-item label="所属单位" label-position="top" prop="organId">
						<a-select @select="changeOrgan" v-model="formData.organId" style="width:100%" placeholder="请选择单位">
							<a-select-option :value="organ.organId" v-for="(organ, index) in organList" :key="index">{{
								organ.name
							}}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col span="7">
					<a-form-model-item label="请选择物品分类" label-position="top" prop="categoryId">
						<a-select v-model="formData.categoryId">
							<a-select-option :value="category.categoryId" v-for="category in goodsCategory"
								:key="category.categoryId">{{ category.name }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col span="8">
					<a-form-model-item label="入库日期" label-position="top" prop="createDate">
						<a-date-picker type="date" v-model="formData.createDate" placeholder="请选择入库日期" />
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-col span="24">
					<a-form-model-item label="跨单位领用" label-position="top" prop="scopeOrganIds">
						<a-select mode="multiple" v-model="formData.scopeOrganIds" style="width: 100%"
							placeholder="不选择默认只有当前单位可领用">
							<a-select-option v-for="item in organList" :key="item.organId">
								{{ item.name }}
							</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-col span="12">
					<a-form-model-item label="请选择部门登记权限" label-position="top" prop="scopeDepIds">
						<select-depart :value="formData.scopeDepIds" multiple
							@input="e => (formData.scopeDepIds = e)"></select-depart>
					</a-form-model-item>
				</a-col>
				<a-col span="12">
					<a-form-model-item label="请选择个人登记权限" label-position="top" prop="scopeStaffIds">
						<select-staff :value="formData.scopeStaffIds" multiple
							@input="e => (formData.scopeStaffIds = e)"></select-staff>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-col span="8">
					<a-form-model-item label="物品名称" label-position="top" prop="name">
						<a-input v-model="formData.name" placeholder="请输入物品名称"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="8" v-if="formData.goodsId == ''">
					<a-form-model-item label="物品单价" label-position="top" prop="price">
						<a-input v-model="formData.price" placeholder="请输入物品单价"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="8">
					<a-form-model-item label="计量单位" label-position="top" prop="mode">
						<a-input v-model="formData.mode" placeholder="请输入物品计量单位"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="8" v-if="formData.goodsId == ''">
					<a-form-model-item label="库存数量" label-position="top" prop="stock">
						<a-input v-model="formData.stock" placeholder="请输入物品数量"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="8">
					<a-form-model-item label="供应商名称" label-position="top">
						<a-input v-model="formData.supplier" placeholder="请输入供应商名称"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="8">
					<a-form-model-item label="物品描述" label-position="top">
						<a-input v-model="formData.description" placeholder="请输入物品描述"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="24">
					<a-form-model-item label="物品详情" label-position="top">
						<editor v-model="formData.content" />
					</a-form-model-item>
				</a-col>
			</a-row>
		</a-form-model>
		<a-spin size="large" fix v-if="loading" />
	</a-modal>
</template>

<script>
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
import selectStaff from '@/components/common/select-staff.vue';
import selectDepart from '@/components/common/select-depart.vue';
import editor from '@/components/component/editor.vue';

export default {
	components: { selectStaff, selectDepart, editor },
	data() {
		return {
			show: false,
			loading: false,
			organList: apiUtil.getAllOrganList(),
			formData: {
				goodsId: '',
				organId: apiUtil.getOrganId(''),
				categoryId: '',
				description: '',
				mode: '',
				name: '',
				price: null,
				scopeOrganIds: [],
				scopeDepIds: [],
				scopeStaffIds: [],
				stock: null,
				supplier: '',
				createDate: '',
				content: ''
			},
			goodsCategory: [],
			formRule: {
				categoryId: [{ required: true, message: '请选择物品分类' }],
				name: [{ required: true, message: '请输入物品名称' }],
				stock: [{ required: true, message: '请输入库存数量' }],
				mode: [{ required: true, message: '请输入计量单位' }],
				price: [{ required: true, message: '请输入物品单价' }],
				createTime: [{ required: true, message: '请选择入库时间' }],

			}
		};
	},
	methods: {
		...apiUtil,
		_show(form) {
			this.show = true;
			this.getgoodsCategory();
			this.$nextTick(() => {
				this.$refs.formRef.resetFields();
				if (form) {
					this.formData = JSON.parse(JSON.stringify(form));
					// console.log(this.formData);
				} else {
					this.formData.goodsId = '';
					this.formData.organId = this.getOrganId();
					this.formData.description = '';
					this.formData.mode = '';
					this.formData.name = '';
					this.formData.price = null;
					this.formData.createTime = null;
					this.formData.scopeDepIds = [];
					this.formData.scopeStaffIds = [];
					this.formData.scopeOrganIds = [];
					this.formData.stock = null;
					this.formData.supplier = '';
					this.formData.content = '';
				}
			});
		},
		_close() {
			this.$refs.formRef.resetFields();
			this.show = false;
			this.$emit('close');
		},
		doSubmit() {
			// console.log(this.formData); return;
			this.$refs.formRef.validate(valid => {
				if (valid) {
					utils.showSpin();
					request.post('/platform/goods/save', this.formData).then(ret => {
						this.show = false;
						if (ret.code == 200) {
							utils.success('操作成功！').then(() => {
								this.$emit('success');
								this.$refs.formRef.resetFields();
								this.show = false;
							});
						}
					});
				}
			});
		},
		// 获取物品分类
		getgoodsCategory() {
			if (this.formData.organId == 0) {
				this.formData.organId = apiUtil.getDefaultOrganId();
			}
			this.http.get('/platform/goods/category', { organId: this.formData.organId }).then(ret => {
				if (ret.code == 200) {
					// console.log(ret.data);
					this.goodsCategory = ret.data;
				}
			});
		},
		changeOrgan() {
			this.formData.categoryId = null;
			this.getgoodsCategory();
		}
	}
};
</script>

<style scoped></style>
