<template>
	<div>
		<a-layout>
			<div class="content-header">
				<a-affix :offset-top="0" style="width: 100%;">
					<a-row type="flex" justify="space-between">
						<a-col span="12">
							<common-page-btn @save="$refs.goodsForm._show()" @category="$refs.category._show()"
								@stock="stock"></common-page-btn>
						</a-col>
						<a-col span="12">
							<a-row type="flex" align="middle" justify="end" :gutter="3">
								<a-col span="6">
									<a-input v-model="param.keyword" allowClear search @pressEnter="doRefresh"
										@on-clear="doRefresh" placeholder="关键词查询"></a-input>
								</a-col>
								<a-col span="6">
									<a-select v-model="param.categoryId" style="width:100%" @change="doRefresh()">
										<a-select-option value="">所有分类</a-select-option>
										<a-select-option v-for="(item, index) in categoryList" :key="index"
											:value="item.categoryId">{{ item.name }}</a-select-option>
									</a-select>
								</a-col>
								<a-col span="6" v-if="getOrganId() == 0">
									<a-select v-model="param.organId" style="width:100%" @change="e => {
										filterOrgan(e);
										this.doRefresh();
									}
										" placeholder="根据单位筛选">
										<a-select-option value="">所有单位</a-select-option>
										<a-select-option :value="organ.organId" v-for="(organ, index) in organList"
											:key="index">{{ organ.name
											}}</a-select-option>
									</a-select>
								</a-col>
							</a-row>
						</a-col>
					</a-row>
				</a-affix>
			</div>
		</a-layout>

		<a-table :columns="columns" :defaultExpandAllRows="true" :scroll="{ x: '100%' }" :pagination="false"
			:indentSize="25" :style="{ background: '#FFF' }" size="small" row-key="goodsId" border ref="list"
			:loading="loading" :data-source="data.records">
			<!-- 物品名称-->
			<template slot-scope="row" slot="name">
				<a-button-group shape="circle" size="small">
					【{{ row.categoryName }}】
					<a @click="$refs.detail.show(row)">{{ row.name }}</a>
				</a-button-group>
			</template>
			<!--操作-->
			<template slot-scope="row" slot="action">
				<a-button-group shape="circle" size="small">
					<a-tooltip title="库存登记"><a-button type="link" v-has="'goods:stock'" icon="save"
							@click="showStock(row)"></a-button></a-tooltip>
				</a-button-group>
				<a-button-group shape="circle" size="small">
					<a-tooltip title="修改"><a-button type="link" v-has="'goods:del'" icon="edit"
							@click="$refs.goodsForm._show(row)"></a-button></a-tooltip>
				</a-button-group>
				<a-button-group shape="circle" size="small">
					<a-tooltip title="删除"><a-button type="link" v-has="'goods:del'" icon="delete"
							@click="del(row.goodsId)"></a-button></a-tooltip>
				</a-button-group>
			</template>
			<template slot="footer" v-if="footer.length > 0">
				<div style="width:100%;display: flex;justify-content: center;font-weight: bold;" v-has="'goods:audit'">
					<span v-for="(text, index) in footer" :key="index" style="margin-right:10px">
						{{ text }}
					</span>
				</div>
			</template>
		</a-table>
		<a-row>
			<a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
				<a-pagination show-quick-jumper :defaultPageSize="param.limit" :total="data.total" @change="getNext"
					@showSizeChange="setLimit" show-size-changer :current="data.current"
					:page-size-options="['10', '20', '30', '40', '50', '100']">
					<template slot="buildOptionText" slot-scope="props">
						<span>{{ props.value }}条/页</span>
					</template>
				</a-pagination>
			</a-col>
		</a-row>
		<!-- 添加物品 -->
		<goods-form ref="goodsForm" @success="doRefresh"></goods-form>
		<!-- 库存登记 -->
		<a-modal title="库管登记" :visible="visible" @ok="handleOk" @cancel="handleCancel" centered destroyOnClose>
			<a-form-model :rules="formRule" :model="formData" ref="formRef">
				<a-row :gutter="15">
					<a-col span="16">
						<a-form-model-item label="选择物品" prop="goodsId">
							<template v-if="isSelected">
								<selectgoods @input="selectGoodsItem" />
							</template>
							<template v-else>
								<a-input v-model="goodsName" disabled />
							</template>
						</a-form-model-item>
					</a-col>

				</a-row>
				<a-row :gutter="15">
					<a-col span="8">
						<a-form-model-item label="选择类型" prop="type">
							<a-radio-group v-model="formData.type" button-style="solid">
								<a-radio-button value="采购入库">采购入库</a-radio-button>
								<a-radio-button value="报废">报废</a-radio-button>
							</a-radio-group>
						</a-form-model-item>
					</a-col>
					<a-col span="8">
						<a-form-model-item label="输入物品数量" prop="number">
							<a-input v-model="formData.number" placeholder="请输入物品数量" type="number"></a-input>
						</a-form-model-item>
					</a-col>
					<a-col span="8">
						<a-form-model-item label="本次采购价格" prop="price" :required="formData.type == '采购入库'">
							<a-input v-model="formData.price" placeholder="请输入采购价格" :disabled="formData.type != '采购入库'"
								type="number"></a-input>
						</a-form-model-item>
					</a-col>
					<a-col span="24">
						<a-form-model-item label="输入备注">
							<a-input v-model="formData.remark" placeholder="请输入备注"></a-input>
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
		</a-modal>

		<!-- 分类设置 -->
		<category ref="category"></category>
		<!-- 物品详情 -->
		<detail ref="detail"></detail>
	</div>
</template>

<script>
import apiUtil from '@/common/apiUtil';
import goodsForm from './components/goodsForm.vue';
import utils from '@/common/utils';
import category from './components/category.vue';
import selectgoods from './components/selectgoods.vue';

import detail from './components/detail.vue';
export default {
	components: { goodsForm, category, selectgoods, detail },
	data() {
		return {
			visible: false,
			data: {},
			records: [],
			loading: false,
			organList: apiUtil.getOrganList(),
			pageSizeOptions: ['10', '20', '30', '40', '50'],
			pageSize: 10,
			total: '',
			formData: {
				goodsId: '',
				type: '采购入库',
				number: '',
				remark: '',
				price: ''
			},
			param: {
				page: 1,
				limit: 20,
				keyword: '',
				organId: apiUtil.getOrganId(''),
				categoryId: ''
			},
			columns: [
				{ title: '物品名称', scopedSlots: { customRender: 'name' }, ellipsis: true },
				{ title: '单价', customRender: (res) => { return '￥' + res.price.toFixed(2) }, align: 'center', width: 80, ellipsis: true },
				{ title: '库存数量', dataIndex: 'stock', align: 'center', width: 100, ellipsis: true },
				{ title: '计量单位', dataIndex: 'mode', align: 'center', width: 100 },
				{ title: '入库日期', dataIndex: 'createDate', align: 'center', width: 160 },
				{ title: '创建人', dataIndex: 'createStaffName', align: 'center', width: 100 },
				{ title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 100 }
			],
			formRule: {
				type: [{ required: true, message: '请选择类型' }],
				goodsId: [{ required: true, message: '请选择物品' }],
				number: [{ required: true, message: '请输入数量' }],
				price: [{ required: false, message: '请输入价格' }],
			},
			isSelected: true,//是否允许选择物品
			goodsName: '',//物品名称
			categoryList: [],
			footer: []
		};
	},
	mounted() {
		this.getGoodsList();
		this.getCategoryList();
		this.getFooter();
	},
	methods: {
		...apiUtil,
		// 获取数据
		getGoodsList() {
			this.loading = true;
			this.http
				.get('/platform/goods/list', this.param)
				.then(ret => {
					this.data = ret.data;
					this.records = ret.data.records;
					this.total = data.total;
					this.param.current = data.current;
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
				});
		},
		// 删除
		del(id) {
			this.utils.confirm('删除后无法恢复，是否确认?').then(() => {
				utils.showSpin();
				this.http.delete('/platform/goods/del/' + id).then(ret => {
					if (ret.code == 200) {
						this.utils.success('操作成功！').then(() => {
							this.getGoodsList();
						});
					}
				});
			});
		},
		getCategoryList() {
			const organId = this.param.organId;
			this.http
				.get(`/platform/goods/category`, { organId: organId })
				.then(ret => {
					this.categoryList = ret.data;
				})
		},
		clearformdata() {
			this.formData.goodsId = '';
			this.formData.type = '采购入库';
			this.formData.number = '';
			this.formData.remark = '';
			this.formData.price = '';
			this.isSelected = true;
			this.goodsName = '';
		},
		// 登记库存
		stock() {
			this.visible = true;
			this.clearformdata();
		},
		showStock(goods) {
			this.visible = true;
			this.clearformdata();
			this.isSelected = false;
			this.goodsName = goods.name;
			this.formData.price = goods.price
			this.selectGoodsItem([goods.goodsId])
		},
		selectGoodsItem(e) {
			this.formData.goodsId = e[0];
		},
		// 保存登记库存
		handleOk() {
			this.$refs.formRef.validate(valid => {
				if (valid) {
					this.http
						.post('/platform/goods/stock', this.formData)
						.then(ret => {
							if (ret.code == 200) {
								this.visible = false;
								this.getGoodsList();
							}
						})
						.catch(err => { });
				}
			});
		},
		getFooter() {
			this.http.get("/platform/goods/stockMoney", { categoryId: this.param.categoryId }).then(ret => {
				// console.log(ret)
				this.footer = [
					`总库存数量合计：${ret.data.totalStock} 件`,
					`总库存金额合计：￥${ret.data.totalMoney} 元`
				];
			})
		},
		// 取消登记库存
		handleCancel() {
			this.visible = false;
			// this.formData = {};
			this.$refs.formRef.resetFields();
		},
		// 筛选条件变化重新获取数据
		doRefresh() {
			this.param.page = 1;
			this.getGoodsList();
		},

		// 筛选单位
		filterOrgan(e) {
			this.param.organId = e;
		},
		//设置每页的显示条数
		setLimit(current, limit) {
			this.param.page = 1;
			this.param.limit = limit;
			this.getGoodsList();
		},
		//页码切换
		getNext(page) {
			this.param.page = page;
			this.getGoodsList();
		}
	},
	watch: {
		'param.categoryId'(val) {
			this.getFooter();
		}
	}
};
</script>

<style></style>
